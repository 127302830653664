import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Line } from 'react-chartjs-2';
import { get } from 'lodash';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import Loading from '../components/Loading';
import ErrorMessage from '../components/ErrorMessage';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as XLSX from 'xlsx'

const apiKey = process.env.REACT_APP_API_KEY;

class UsageByDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(),
      endDate: moment(),
      totalMinutes: 0,
      totalMinutesData: [],
      resources: []
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.totalMinutes !== this.state.totalMinutes) {
      this.setState({
        totalMinutes: this.state.totalMinutes,
        totalMinutesData: this.state.totalMinutesData,
        resources: this.state.resources
      });
    }
  }

  setStartDate(date) {
    this.setState({
      startDate: date
    });
  }

  setEndDate(date) {
    this.setState({
      endDate: date
    });
  }

  updateTotalMinutes(total, data, resources) {
    if (this.state.totalMinutes !== total) {
      this.setState({
        totalMinutes: total,
        totalMinutesData: data,
        resources: resources
      });
    }
  }

  generateXLSX = async () => {
    const [firstNumber, ...restNumbers] = this.state.totalMinutesData;

    const information = [
      { 
        intervalStart: this.state.resources[0]?.intervalStart.split('T')[0] || 'NaN',
        intervalEnd: this.state.resources[0]?.intervalEnd.split('T')[0] || 'NaN',
        data: firstNumber !== null ? parseFloat(firstNumber) : 'NaN', 
        totalMinutes: this.state.totalMinutes 
      },
      ...restNumbers.map((number, index) => ({ 
        intervalStart: this.state.resources[index + 1]?.intervalStart.split('T')[0] || 'NaN',
        intervalEnd: this.state.resources[index + 1]?.intervalEnd.split('T')[0] || 'NaN',
        data: number !== null ? parseFloat(number) : 'NaN' 
      }))
    ];

    const headers = ['intervalStart', 'intervalEnd', 'data', 'totalMinutes'];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(information, { header: headers });
    XLSX.utils.book_append_sheet(wb, ws, 'Information');
    XLSX.writeFile(wb, `Information.xlsx`);
  }

  render() {
    const { startDate, endDate } = this.state;
    const formattedStartDate = startDate.clone().add(1, 'days').format('YYYY-MM-DD');
    const formattedEndDate = endDate.clone().add(1, 'days').format('YYYY-MM-DD');

    const query = gql`
      {
        project(projectId: "${apiKey}") {
          projectData(
            start: "${formattedStartDate}",
            end: "${formattedEndDate}",
            interval: DAILY
          ) { 
            resources {
              intervalStart,
              intervalEnd,
              usage {
                sdArchiveComposedMinutes
              }
            }
          }
        }
      }
    `;

    return (
      <div>
        <div>
          <p>Total minutes: {this.state.totalMinutes}</p>
          <button onClick={this.generateXLSX}>Download Excel</button>
        </div>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            margin="normal"
            id="start-date-picker-inline"
            minDate={moment(endDate).subtract(1, 'year').startOf('day')}
            maxDate={endDate}
            label="Fecha de inicio"
            value={startDate}
            onChange={this.setStartDate}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            margin="normal"
            id="end-date-picker-inline"
            label="Fecha de fin"
            minDate={startDate}
            maxDate={moment(startDate).add(1, 'year').startOf('day')}
            value={endDate}
            onChange={this.setEndDate}
          />
        </MuiPickersUtilsProvider>
        <Query key={`qid-${moment(startDate).valueOf()}`} query={query}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <ErrorMessage error={error.message} />;
            const resources = get(data, 'project.projectData.resources', []);
            console.log(resources)
            const totalMinutesDataSet = {
              label: 'Usage per Minutes',
              backgroundColor: 'rgba(255,99,132,0.4)',
              data: resources.map(item => {
                const sdArchiveComposedMinutes = get(item, 'usage.sdArchiveComposedMinutes', 0);
                return sdArchiveComposedMinutes;
              }),
            };

            const total = totalMinutesDataSet.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            this.updateTotalMinutes(total, totalMinutesDataSet.data, resources);

            return (
              <div>
                <Line
                  data={{
                    labels: resources.map(item => moment(item.intervalStart).format('MMM DD')),
                    datasets: [
                      totalMinutesDataSet,
                    ],
                  }}
                  options={{
                    scales: {
                      yAxes: [{
                        stacked: true,
                      }],
                    },
                  }}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default UsageByDay;
